<script setup>
import { reactive } from 'vue'
import { useSidePanel } from '@/stores/sidepanel'
import SidePanelMenu from '@/components/layout/panel/SidePanelMenu.vue'
import QuoteSidepanel from '@/components/Sheets/quote/estimating/QuoteSidepanel.vue'

const sidePanelStore = useSidePanel()

const items = reactive([
  {
    label: 'Estimate',
    icon: 'table',
    tab: 'Estimate'
  },
  {
    label: 'Preview',
    icon: 'eye',
    tab: 'Preview'
  }
])
</script>

<template>
  <div class="w-full h-screen flex flex-col justify-between">
    <div
      class="md:hidden px-4 h-[60px] w-full m-0 flex flex-row justify-between items-center border-b border-cement-600"
    >
      <span class="text-[20px] semi-bold">Menu</span>
      <font-awesome-icon
        icon="times"
        size="lg"
        class="cursor-pointer"
        @click.native="sidePanelStore.toggle"
      />
    </div>
    <SidePanelMenu :model="items" :allowStarred="false" />
    <QuoteSidepanel class="border-t border-cement-600 overflow-y-auto shrink-0 max-h-[70vh]" />
  </div>
</template>
